// extracted by mini-css-extract-plugin
export var alignLeft = "B_p5 g_gm g_cK g_fy";
export var alignCenter = "B_cS g_gn g_cH g_fz";
export var alignRight = "B_p6 g_gp g_cL g_fB";
export var element = "B_tx g_dw g_dk";
export var customImageWrapper = "B_ty g_dw g_dk g_b5";
export var imageWrapper = "B_rF g_dw g_b5";
export var masonryImageWrapper = "B_qR";
export var gallery = "B_tz g_bD g_cD";
export var width100 = "B_bD";
export var map = "B_tB g_bD g_bP g_b5";
export var quoteWrapper = "B_hH g_cG g_cS g_dw g_dk g_fy";
export var quote = "B_tC g_cG g_cS g_fy";
export var quoteBar = "B_qK g_bP";
export var quoteText = "B_qL";
export var customRow = "B_qZ g_cH g_b5";
export var separatorWrapper = "B_tD g_bD g_cD";
export var articleText = "B_qn g_dw";
export var videoIframeStyle = "B_qF g_f3 g_bD g_bP g_cC g_c4 g_bY";