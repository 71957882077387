// extracted by mini-css-extract-plugin
export var articleWrapper = "p_qm";
export var articleText = "p_qn g_fy g_dw";
export var header = "p_qp g_b5 g_bD";
export var headerImageWrapper = "p_qq g_cl g_b6 g_cg g_cf g_cb g_ch g_cn g_cV";
export var headerGradient = "p_qr g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerGradientOverlay = "p_qs g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerContentWrapper = "p_qt g_cD g_cS g_cH g_cM g_cp g_b5";
export var headerContentWrapperAlt = "p_qv g_cD g_cS g_cH g_cM g_cp g_b5";
export var contentWrapper = "p_qw g_bD";
export var dateTag = "p_qx g_dj g_dv g_ft g_bD";
export var icon1 = "p_qy g_dS";
export var icon2 = "p_qz g_dS g_dM";
export var tagBtn = "p_qB g_c4 g_dH g_fs g_fF";
export var headerText = "p_qC g_bD g_dj g_dw g_cZ";
export var center = "p_qD g_fz";
export var videoIframeStyle = "p_qF g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var articleImageWrapper = "p_qG g_dw g_b5";
export var articleImageWrapperIcon = "p_qH g_dw g_b5";
export var articleRow = "p_qJ g_cH";
export var quoteWrapper = "p_hH g_cG g_cS g_dw";
export var quoteBar = "p_qK g_bP";
export var quoteText = "p_qL";
export var authorBox = "p_qM g_bD";
export var authorRow = "p_qN g_cH g_cZ g_dC";
export var separator = "p_qP g_bD";
export var line = "p_gh g_bD g_gh g_d1 g_dC";
export var authorImage = "p_l4 g_db g_b5 g_bD g_dx";
export var authorText = "p_qQ g_dl g_dx";
export var masonryImageWrapper = "p_qR";
export var bottomSeparator = "p_qS g_bD g_dC";
export var linksWrapper = "p_qT g_dl g_fy";
export var comments = "p_qV g_cH g_dC";
export var sharing = "p_qW g_cD g_cH g_cM";
export var shareText = "p_qX g_bD g_fz";
export var icon = "p_qY";
export var customRow = "p_qZ g_cH g_b5";
export var text = "p_q0";
export var SubtitleSmall = "p_q1";
export var SubtitleNormal = "p_q2";
export var SubtitleLarge = "p_q3";