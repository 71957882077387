// extracted by mini-css-extract-plugin
export var noPadding = "r_gg g_gg";
export var mapFrameWrapper = "r_rc g_lB g_c8";
export var mapFrameWrapperOSM = "r_rd g_lC g_c8 g_bD g_bX g_bG g_bT";
export var iframeStyle = "r_f3 g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var mapLayoutRight = "r_rf g_lz g_cN";
export var mapInnerWrapper = "r_rg g_f9 g_f8 g_bW g_b5 g_cD g_cH g_cS g_c8";
export var mapText = "r_rh g_fz";
export var mapStyle = "r_rj g_bD g_bP g_cC g_c4";
export var font11pxImp = "r_bt g_bt";
export var mapInnerWrapperOSM = "r_lG g_lG g_d5 g_d0 g_cG g_cS g_cH";
export var mapInnerWrapperOSMFloaty = "r_lD g_lD g_b5";
export var mapInnerWrapperOSMFloatyMobile = "r_lF g_lF g_b5";
export var minHeight100 = "r_bT g_bT";
export var height100 = "r_bP g_bP";
export var width100 = "r_bD g_bD";
export var positionAbsolute = "r_b6";
export var positionRelative = "r_b5 g_b5";