// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "s_rk g_hK g_dz g_fy";
export var quoteParagraphCenter = "s_hL g_hL g_dz g_fz";
export var quoteParagraphRight = "s_hM g_hM g_dz g_fB";
export var quoteRowLeft = "s_rl g_cK";
export var quoteRowCenter = "s_rm g_cH";
export var quoteRowRight = "s_rn g_cL";
export var quoteWrapper = "s_hH g_hH g_bD g_dH g_cD g_cS g_cH";
export var quoteContentWrapper = "s_hJ g_hJ";
export var quoteExceptionSmall = "s_rp y_rp";
export var quoteExceptionNormal = "s_rq y_rq";
export var quoteExceptionLarge = "s_rr y_rr";
export var quoteAuthorExceptionSmall = "s_rs y_rs";
export var quoteAuthorExceptionNormal = "s_rt y_rt";
export var quoteAuthorExceptionLarge = "s_rv y_rv";