// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_tj";
export var lbContainerInner = "z_tk";
export var movingForwards = "z_tl";
export var movingForwardsOther = "z_tm";
export var movingBackwards = "z_tn";
export var movingBackwardsOther = "z_tp";
export var lbImage = "z_tq";
export var lbOtherImage = "z_tr";
export var prevButton = "z_ts";
export var nextButton = "z_tt";
export var closing = "z_tv";
export var appear = "z_tw";