// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "d_h";
export var warningBackgrounddark = "d_j";
export var warningBackgroundcustom = "d_k";
export var bottom = "d_l";
export var modal = "d_m";
export var container = "d_n";
export var btnWrapper = "d_p g_cH g_cS g_cM";
export var elementWrapper = "d_q g_cH g_cS";
export var titleWrapper = "d_r g_cH g_cS";
export var cookieButtons = "d_s g_cD g_cH g_cS g_cM";
export var btn = "d_t g_cD g_cH g_cS";
export var link = "d_v";
export var decline = "d_w d_t g_cD g_cH g_cS";
export var row = "d_x";